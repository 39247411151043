import { SelectableItem } from "@airmont/shared/ts/ui/action";
import { ReactNode, useState } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import { PaperElevation } from "@airmont/shared/ts/ui/elevation";
import { Variant } from "./Variant";
import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { NavigationTypeButton } from "./NavigationTypeButton";
import {
  NavigationBar,
  NavigationDrawer,
  NavigationItemObject,
  NavigationRail,
  NavigationType,
} from "@airmont/shared/ts/ui/navigation";
import { AppPageVariantProvider } from "@airmont/shared/ts/ui/app-page";

const resolveNavigationType = (breakpoint: SizeClass): NavigationType => {
  if (breakpoint === SizeClass.Compact) {
    return "bar";
  } else if (breakpoint === SizeClass.Medium) {
    return "rail";
  } else if (breakpoint === SizeClass.Expanded) {
    return "rail";
  } else if (breakpoint === SizeClass.ExtraLarge) {
    return "drawer";
  }

  return "bar";
};

export interface AppLayoutProps {
  navigationItems:
    | Array<NavigationItemObject>
    | ((navigationType: NavigationType) => Array<NavigationItemObject>);
  variant?: Variant;
  onNavigationItemSelected: (item: SelectableItem) => void;
  main: ReactNode;
  navigationType?: NavigationType;
  paperElevation?: number;
  disableUserControlledNavigationType?: boolean;
}

export const AppLayout = (props: AppLayoutProps) => {
  const theme = useTheme();
  const variant = props.variant ?? "elevation";
  const { windowWidth } = useWindowSize();
  const disableUserControlledNavigationType =
    props.disableUserControlledNavigationType ?? false;

  const navigationTypeByWidth =
    props.navigationType != null
      ? props.navigationType
      : resolveNavigationType(windowWidth);
  const [navigationTypeByUser, setNavigationTypeByUser] =
    useUserSettingWithDefault<string | null>(
      "AppLayout.navigationType",
      StringSetting,
      null,
      {
        storeLocally: true,
      }
    );
  const navigationType =
    navigationTypeByUser != null && navigationTypeByWidth !== "bar"
      ? (navigationTypeByUser as NavigationType)
      : navigationTypeByWidth;

  const [hovering, setHovering] = useState<boolean>(false);

  const handleToggleNavigationTypeByUser = () => {
    setHovering(false);
    if (navigationTypeByUser == null && navigationTypeByWidth === "drawer") {
      setNavigationTypeByUser("rail");
    } else if (
      navigationTypeByUser == null &&
      navigationTypeByWidth === "rail"
    ) {
      setNavigationTypeByUser("drawer");
    } else if (navigationTypeByUser === "drawer") {
      setNavigationTypeByUser("rail");
    } else if (
      navigationTypeByWidth === "rail" &&
      navigationTypeByUser === "rail"
    ) {
      setNavigationTypeByUser("drawer");
    } else if (
      navigationTypeByUser === "rail" &&
      navigationTypeByWidth === "drawer"
    ) {
      setNavigationTypeByUser(null);
    }
  };

  const handleNavigationTypeButtonHover = (hovering: boolean) => {
    setHovering(hovering);
  };

  const flexDirection = navigationType === "bar" ? "column-reverse" : "row";
  const showNavigationTypeButton =
    !disableUserControlledNavigationType && navigationType !== "bar";
  return (
    <Box
      className={`AppLayout AppLayout-${navigationType}`}
      sx={{
        height: "100%",
        padding: 0,
        display: "flex",
        flexDirection: flexDirection,
        position: "relative",
      }}
    >
      <PaperElevation elevation={props.paperElevation ?? 1}>
        {navigationType === "bar" && (
          <NavigationBar
            items={
              typeof props.navigationItems === "function"
                ? props.navigationItems(navigationType)
                : props.navigationItems
            }
            variant={variant}
            className={"navigation"}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType === "rail" && (
          <NavigationRail
            items={
              typeof props.navigationItems === "function"
                ? props.navigationItems(navigationType)
                : props.navigationItems
            }
            variant={variant}
            className={"navigation"}
            sx={{ width: 80, margin: theme.spacing(1) }}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType === "drawer" && (
          <NavigationDrawer
            items={
              typeof props.navigationItems === "function"
                ? props.navigationItems(navigationType)
                : props.navigationItems
            }
            variant={variant}
            className={"navigation"}
            sx={{
              margin: 1,
              padding: 1,
              ...(variant === "flat" && {
                padding: 1,
              }),
            }}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType !== "bar" && (
          <Divider
            orientation={"vertical"}
            sx={{
              position: "relative",
              borderColor: hovering
                ? theme.palette.primary.dark
                : variant === "elevation"
                ? "transparent"
                : undefined,
            }}
          ></Divider>
        )}
        {showNavigationTypeButton && (
          <NavigationTypeButton
            navigationTypeByWidth={navigationTypeByWidth}
            navigationTypeByUser={navigationTypeByUser as NavigationType | null}
            variant={variant}
            onToggle={handleToggleNavigationTypeByUser}
            onHover={handleNavigationTypeButtonHover}
          />
        )}
        <Box
          className={"main"}
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            "> *": {
              flexGrow: 1,
              minWidth: 0,
              minHeight: 0,
            },
          }}
        >
          <AppPageVariantProvider value={variant}>
            {props.main}
          </AppPageVariantProvider>
        </Box>
      </PaperElevation>
    </Box>
  );
};

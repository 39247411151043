import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC, useMemo } from "react";
import { merge } from "lodash";

export const ChimneyHatIcon: FC<SvgIconProps> = (props) => {
  const sx = useMemo(() => {
    return merge({}, props.sx, {
      width: "unset",
      height: "unset",
    });
  }, [props.sx]);

  return (
    <SvgIcon {...props} sx={sx} inheritViewBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 169 90.45"
        stroke="currentColor"
      >
        <path
          strokeMiterlimit="10"
          d="m167,88.45H2v-4.86C2,38.53,38.53,2,83.59,2h1.82c45.06,0,81.59,36.53,81.59,81.59v4.86Z"
        />
      </svg>
    </SvgIcon>
  );
};

import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { useAppTheme } from "./AppThemeProvider";

export const useThemeUserSetting = () => {
  const { themeName } = useAppTheme();
  return useUserSettingWithDefault("theme", StringSetting, themeName, {
    storeLocally: true,
    storeCopyToAppLocally: true,
  });
};

import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import { BurnAssessmentColor } from "./BurnAssessmentColor";
import { BurnAssessment } from "./BurnAssessment";
import { resolveBurnAssessmentIcon } from "./resolveBurnAssessmentIcon";

export class BurnAssessmentUtils {
  static percentToColor(percent: number): string {
    let color: string;
    if (percent >= 67) {
      color = BurnAssessmentColor[BurnAssessment.Good];
    } else if (percent >= 34) {
      color = BurnAssessmentColor[BurnAssessment.Normal];
    } else {
      color = BurnAssessmentColor[BurnAssessment.Bad];
    }
    return color;
  }

  static percentToIcon(percent: number): OverridableComponent<SvgIconTypeMap> {
    if (percent >= 67) {
      return resolveBurnAssessmentIcon(BurnAssessment.Good);
    } else if (percent >= 34) {
      return resolveBurnAssessmentIcon(BurnAssessment.Normal);
    } else {
      return resolveBurnAssessmentIcon(BurnAssessment.Bad);
    }
  }
}

import {
  alpha,
  Card,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useTranslation } from "react-i18next";

export const FlueStatusMapLegend = () => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const [display, setDisplay] = useUserSettingWithDefault(
    "FlueStatusMapLegend.display",
    BooleanSetting,
    true
  );
  const handleToggleDisplay = () => {
    setDisplay((prevDisplay) => !prevDisplay);
  };
  return (
    <Card
      sx={{
        m: 1,
        backgroundColor: alpha(
          theme.palette.background.paper,
          display ? 0.9 : 0.7
        ),
      }}
    >
      <Toolbar
        variant="dense"
        sx={{ "&&": { maxHeight: "40px", minHeight: "40px", pl: 1, pr: 1 } }}
      >
        <Typography sx={{ mr: 1 }}>{t("Legend")}</Typography>
        <IconButton
          onClick={handleToggleDisplay}
          sx={{ ml: "auto", backgroundColor: "transparent", borderRadius: 0 }}
        >
          {display ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </IconButton>
      </Toolbar>
      <Collapse in={display} orientation="horizontal">
        <Collapse in={display} orientation="vertical">
          <List
            sx={{
              ".MuiListItemIcon-root": { minWidth: "unset", mr: 1 },
            }}
          >
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{ color: theme.palette.success.main }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {t("No need for sweep until after {{date}}", {
                  date: t("{{years}} years", { years: 2 }),
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{ color: theme.palette.warning.main }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {t("Needs sweep within {{years}} years", { years: "1-2" })}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <CircleIcon
                  sx={{ color: theme.palette.error.main }}
                  fontSize={"small"}
                />
              </ListItemIcon>
              <ListItemText>
                {t("Needs sweep within {{months}} months", { months: 12 })}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </Collapse>
    </Card>
  );
};

export class RelativeTimeFormatPartUtils {
  static toString(parts: Intl.RelativeTimeFormatPart[]): string {
    let result = "";
    parts.forEach((part, index) => {
      result += part.value;
      if (part.type === "literal" && index < parts.length - 1) {
        result += " ";
      }
    });
    return result;
  }
}

import { ServerErrorFactory } from "./ServerErrorFactory";
import { FetchError } from "./FetchError";

export const defaultServerErrorFactory: ServerErrorFactory = async (
  response,
  request
) => {
  try {
    return Promise.reject(
      new FetchError({ response: response, request: request })
    );
  } catch (errorWhileParsingJson) {
    return Promise.reject(
      new FetchError({ response: response, request: request })
    );
  }
};

import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import i18next from "i18next";
import { Dispatch, SetStateAction } from "react";

export type UseLanguageUserSettingReturnType = [
  string,
  Dispatch<SetStateAction<string>>
];

export const useLanguageUserSetting = (
  defaultLanguage?: string
): UseLanguageUserSettingReturnType => {
  const defaultValue = defaultLanguage ?? i18next.language ?? "en";
  return useUserSettingWithDefault("language", StringSetting, defaultValue, {
    storeCopyToAppLocally: true,
  });
};

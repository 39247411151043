import { FC } from "react";
import { NavigationLessAppPage } from "./NavigationLessAppPage";
import { useAppInfo } from "shared-ts-app-info";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

export interface UserSettingsLoadingPageProps {
  appNameTranslated?: string;
}

export const UserSettingsLoadingPage: FC<UserSettingsLoadingPageProps> = (
  props
) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation("shared-ts-ui-app-boot");
  return (
    <NavigationLessAppPage
      appName={props.appNameTranslated ?? appInfo.name}
      appIcon={appInfo.icon?.large}
      sx={{
        pt: 2,
        pb: 2,
      }}
    >
      <Stack>
        <Typography textAlign={"center"}>
          {t("Loading User Settings")}
          <DotsProgress />
        </Typography>
      </Stack>
    </NavigationLessAppPage>
  );
};

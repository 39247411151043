import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { boot } from "./app/boot/boot";
import { AppUnauthenticated } from "./app/unauthenticated/AppUnauthenticated";
import {
  OidcAccessTokenProvider,
  OidcAuthenticationService,
  OidcProvider,
  OidcUserProvider,
} from "@airmont/firefly/shared/ts/authentication-oidc";
import { WebStorageStateStore } from "oidc-client";
import { AccessTokenContextProvider } from "shared-ts-utils-authentication";
import { _throw, NullError } from "@airmont/shared/ts/utils/core";
import {
  AppBoot,
  BootPage,
  UserSettingsLoadingPage,
} from "@airmont/shared/ts/ui/app-boot";
import { ChimneyInsightsBootResult } from "./app/boot/ChimneyInsightsBootResult";
import { WindowSizeProvider } from "@airmont/shared/ts/ui/responsive";
import { I18NextProvider } from "@airmont/shared/ts/utils/i18n";
import { languages } from "./languages";
import { AppInfoProvider } from "shared-ts-app-info";
import { appInfo, appName } from "./appInfo";
import {
  ServerSettingsStorage,
  UserSettingsProvider,
} from "@airmont/shared/ts/utils/user-settings";
import { alpha, hexToRgb } from "@mui/material";
import {
  AppThemeInitializer,
  AppThemeProvider,
} from "@airmont/shared/ts/ui/app-theme-provider";
import { MuiLocalizationProvider } from "shared-ts-mui";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { App } from "./app/App";
import {
  PostHogConsentProvider,
  PostHogIdentifier,
  PostHogInitializer,
} from "shared-ts-posthog";
import { darkThemeOptions, lightThemeOptions } from "./app/theme";
import { LanguageUserSettingProvider } from "shared-ts-language";
import { LocaleProvider } from "shared-ts-locale";
import { EnvironmentProvider } from "@airmont/firefly/shared/ts/customer-environment";
import {
  MunicipalityContextProvider,
  SystemDao,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { Settings } from "luxon";
import { SentryConsentProvider, SentryInitializer } from "shared-ts-sentry";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WhatsNewProvider } from "@airmont/firefly/chimney-insights/ts/pages";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const authenticationService = new OidcAuthenticationService({
  authority: "https://auth.airmontcloud.net",
  client_id: "Firefly.CustomerWeb",
  redirect_uri: window.location.origin + "/authentication/login-callback",
  scope: "openid FirehiveAPI", //scope dictates the "aud" claim(s) (audience) included in the access_token
  response_type: "id_token token",
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  userStore: new WebStorageStateStore({
    prefix: "Firefly.CustomerWeb",
  }),
});

const i18nFallbackLng = {
  "nb-NO": ["no", "en"],
  "nn-NO": ["no", "en"],
  "en-US": ["en"],
};
const i18nResources = [
  {
    namespace: "app",
    loader: import("./.i18n"),
  },
  {
    namespace: "firefly-chimney-insights-ts-pages",
    loader: import("@airmont/firefly/chimney-insights/ts/pages"),
  },
  {
    namespace: "firefly-chimney-insights-ts-settings",
    loader: import("@airmont/firefly/chimney-insights/ts/settings"),
  },
  {
    namespace: "firefly-chimney-insights-ts-domain",
    loader: import("@airmont/firefly/chimney-insights/ts/domain"),
  },
  {
    namespace: "firefly-shared-ts-domain",
    loader: import("@airmont/firefly/shared/ts/domain"),
  },
  {
    namespace: "shared-ts-ui-properties-card",
    loader: import("@airmont/shared/ts/ui/properties-card"),
  },
  {
    namespace: "shared-ts-ui-timeframe",
    loader: import("@airmont/shared/ts/ui/timeframe"),
  },
  {
    namespace: "shared-ts-ui-app-boot",
    loader: import("@airmont/shared/ts/ui/app-boot"),
  },
  {
    namespace: "shared-ts-ui-time",
    loader: import("@airmont/shared/ts/ui/time"),
  },
  {
    namespace: "shared-ts-ui-react-google-maps",
    loader: import("shared-ts-ui-react-google-maps"),
  },
  {
    namespace: "shared-ts-release",
    loader: import("shared-ts-release"),
  },
  {
    namespace: "shared-ts-app-dialog",
    loader: import("shared-ts-app-dialog"),
  },
  {
    namespace: "shared-ts-app-info",
    loader: import("shared-ts-app-info"),
  },
  {
    namespace: "shared-ts-language",
    loader: import("shared-ts-language"),
  },
  {
    namespace: "shared-ts-image-upload",
    loader: import("shared-ts-image-upload"),
  },
  {
    namespace: "shared-ts-sentry",
    loader: import("shared-ts-sentry"),
  },
  {
    namespace: "shared-ts-posthog",
    loader: import("shared-ts-posthog"),
  },
  {
    namespace: "shared-ts-mui",
    loader: import("shared-ts-mui"),
  },
];

const rootElement = document.getElementById("root");
const root = createRoot(rootElement ?? _throw(new NullError("rootElement"))); // createRoot(container!) if you use TypeScript
const systemDao = new SystemDao();
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppInfoProvider
        appInfo={appInfo}
        getBuildNumber={systemDao.getBuildNumber}
      >
        {(appInfo) => {
          Settings.throwOnInvalid = appInfo.buildVersion === "local-debug";
          return (
            <UserSettingsProvider>
              {(settings) => {
                return (
                  <AppThemeProvider
                    initialTheme={settings.readString("theme")}
                    themes={{
                      dark: darkThemeOptions,
                      light: lightThemeOptions,
                    }}
                    additionalGlobalStyles={(theme) => ({
                      ".gm-style button:not(.MuiButtonBase-root)": {
                        backgroundColor: `${alpha(
                          hexToRgb(theme.palette.background.paper),
                          1
                        )} !important`,
                        color: `${theme.palette.text.secondary} !important`,
                      },
                      ".gm-svpc": {
                        backgroundColor: `${alpha(
                          hexToRgb(theme.palette.background.paper),
                          1
                        )} !important`,
                      },
                      ".gm-style .gm-style-iw-c": {
                        backgroundColor: alpha(
                          hexToRgb(theme.palette.background.paper),
                          1
                        ),
                        padding: "0 !important",
                      },
                      ".gm-style .gm-style-iw-d .gm-style-iw-d": {
                        overflow: "hidden; !important",
                      },
                      ".gm-style-iw + div": {
                        display: "none",
                      },
                      ".gm-ui-hover-effect > span": {
                        backgroundColor: theme.palette.text.secondary,
                      },
                      ".gm-style .gm-style-iw-t::after": {
                        backgroundColor: alpha(
                          hexToRgb(theme.palette.background.paper),
                          0.8
                        ),
                      },
                      ".gm-style .gm-style-iw-d::-webkit-scrollbar": {
                        display: "none",
                      },
                      ".gm-style .gm-style-iw-d::-webkit-scrollbar-track": {
                        backgroundColor: alpha(
                          hexToRgb(theme.palette.background.paper),
                          0.8
                        ),
                      },
                      ".gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece":
                        {
                          backgroundColor: alpha(
                            hexToRgb(theme.palette.background.paper),
                            0.8
                          ),
                        },
                    })}
                  >
                    <WindowSizeProvider>
                      <I18NextProvider
                        language={settings.readString("language") ?? "nb-NO"}
                        languages={languages}
                        fallbackLng={i18nFallbackLng}
                        resources={i18nResources}
                        debug={true}
                      >
                        <OidcProvider instance={authenticationService}>
                          <OidcAccessTokenProvider>
                            {(token) => (
                              <AccessTokenContextProvider token={token}>
                                <AppBoot
                                  bootPage={({ messages, error }) => (
                                    <BootPage
                                      appName={appName}
                                      booting={"Fyrer opp applikasjonen"}
                                      messages={messages}
                                      error={error}
                                      appIcon={<AirmontLogoImg />}
                                    />
                                  )}
                                  boot={boot}
                                  app={(bootResult) => {
                                    const chimneyInsightsBootResult =
                                      bootResult as ChimneyInsightsBootResult;
                                    return (
                                      <OidcUserProvider
                                        user={chimneyInsightsBootResult.user}
                                      >
                                        <UserSettingsProvider
                                          storage={
                                            new ServerSettingsStorage({
                                              accessToken:
                                                chimneyInsightsBootResult.user
                                                  .accessToken,
                                            })
                                          }
                                          loadingComponent={
                                            <UserSettingsLoadingPage />
                                          }
                                        >
                                          {(settings) => {
                                            return (
                                              <LanguageUserSettingProvider
                                                defaultLanguage={"nb-NO"}
                                              >
                                                {(language) => {
                                                  return (
                                                    <LocaleProvider
                                                      locale={language}
                                                    >
                                                      <MuiLocalizationProvider
                                                        language={language}
                                                      >
                                                        <AppThemeInitializer>
                                                          <WhatsNewProvider>
                                                            <SentryConsentProvider>
                                                              <SentryInitializer
                                                                dsn={
                                                                  "https://4af676ad030f48b88b608fde4678ee0d@o4504604658761728.ingest.sentry.io/4504604702212096"
                                                                }
                                                              >
                                                                <PostHogConsentProvider>
                                                                  <PostHogInitializer
                                                                    apiKey={
                                                                      "phc_ah4lqv2x9krqEkmIcqzQpTUDIxIsxSvk5EGz42CFLux"
                                                                    }
                                                                    options={{
                                                                      api_host:
                                                                        "https://eu.i.posthog.com",
                                                                    }}
                                                                  >
                                                                    <PostHogIdentifier
                                                                      user={
                                                                        chimneyInsightsBootResult.user
                                                                      }
                                                                    >
                                                                      <EnvironmentProvider
                                                                        initialUserSettings={
                                                                          settings
                                                                        }
                                                                        environments={
                                                                          chimneyInsightsBootResult.customerEnvironments
                                                                        }
                                                                      >
                                                                        {(
                                                                          customerEnvironment
                                                                        ) => {
                                                                          Sentry.setTags(
                                                                            {
                                                                              customerEnvironment:
                                                                                customerEnvironment.id,
                                                                            }
                                                                          );
                                                                          return (
                                                                            <MunicipalityContextProvider
                                                                              initialMunicipalities={
                                                                                chimneyInsightsBootResult.municipalities
                                                                              }
                                                                            >
                                                                              <App
                                                                                bootResult={
                                                                                  chimneyInsightsBootResult
                                                                                }
                                                                              />
                                                                            </MunicipalityContextProvider>
                                                                          );
                                                                        }}
                                                                      </EnvironmentProvider>
                                                                    </PostHogIdentifier>
                                                                  </PostHogInitializer>
                                                                </PostHogConsentProvider>
                                                              </SentryInitializer>
                                                            </SentryConsentProvider>
                                                          </WhatsNewProvider>
                                                        </AppThemeInitializer>
                                                      </MuiLocalizationProvider>
                                                    </LocaleProvider>
                                                  );
                                                }}
                                              </LanguageUserSettingProvider>
                                            );
                                          }}
                                        </UserSettingsProvider>
                                      </OidcUserProvider>
                                    );
                                  }}
                                  appWhenUnauthenticated={() => {
                                    return <AppUnauthenticated />;
                                  }}
                                />
                              </AccessTokenContextProvider>
                            )}
                          </OidcAccessTokenProvider>
                        </OidcProvider>
                      </I18NextProvider>

                      <ReactQueryDevtools
                        initialIsOpen={false}
                        buttonPosition={"top-right"}
                      />
                    </WindowSizeProvider>
                  </AppThemeProvider>
                );
              }}
            </UserSettingsProvider>
          );
        }}
      </AppInfoProvider>
    </QueryClientProvider>
  </StrictMode>
);

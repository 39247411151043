import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { useAnonymizeIssueMonitoringDataWithNullAsDefault } from "./useAnonymizeIssueMonitoringDataWithNullAsDefault";
import { cloneElement, FC, ReactNode } from "react";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { Formik } from "formik";
import { Button, Link, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useConsentToMonitorIssuesWithNullAsDefault } from "./useConsentToMonitorIssuesWithNullAsDefault";
import { ConsentToCollectSentryDataCheckbox } from "./ConsentToCollectSentryDataCheckbox";
import { AnonymizeIssueMonitoringDataCheckbox } from "./AnonymizeIssueMonitoringDataCheckbox";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { notUndef } from "@airmont/shared/ts/utils/core";

export interface SentryConsentProviderProps {
  children: ReactNode;
}

export const SentryConsentProvider: FC<SentryConsentProviderProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-sentry");
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();

  const [consentToMonitorIssues, setConsentToCollectSentryData] =
    useConsentToMonitorIssuesWithNullAsDefault();

  const [, setAnonymizeUsageData] =
    useAnonymizeIssueMonitoringDataWithNullAsDefault();

  const handleConsentFormSubmit = (values: SentryConsentForm) => {
    if (values.consentToMonitorIssues) {
      setAnonymizeUsageData(values.anonymizeIssueMonitoringData);
    } else {
      setAnonymizeUsageData(null);
    }
    setConsentToCollectSentryData(values.consentToMonitorIssues);
  };

  if (consentToMonitorIssues == null) {
    return (
      <NavigationLessAppPage
        appName={appInfo.name}
        appIcon={notUndef(appInfo.icon?.large, (it) =>
          cloneElement(it, { style: { maxHeight: "100px" } })
        )}
        flexGrowHeader={"auto"}
        language={language}
        onLanguageChange={setLanguage}
        themeName={themeName}
        onThemeChange={setTheme}
        disableScrollY
        sx={{ pt: 2, pb: 7 }}
      >
        <Formik<SentryConsentForm>
          initialValues={{
            consentToMonitorIssues: true,
            anonymizeIssueMonitoringData: false,
          }}
          onSubmit={handleConsentFormSubmit}
        >
          {({ values, setFieldValue, submitForm }) => {
            return (
              <Stack useFlexGap gap={2} sx={{ flexGrow: 1, minHeight: 0 }}>
                <Stack
                  useFlexGap
                  gap={2}
                  sx={{ flexGrow: 1, minHeight: 0, overflowY: "auto" }}
                >
                  <Typography>
                    <Trans
                      i18nKey={"airmont uses Sentry ..."}
                      ns={"shared-ts-sentry"}
                      values={{ ApplicationName: appInfo.name }}
                      components={{
                        Link: (
                          <Link
                            href={"https://www.sentry.com"}
                            target={"_blank"}
                          />
                        ),
                      }}
                    />
                  </Typography>
                  <Typography>
                    {t("Sentry stores the issue monitoring data ...")}
                  </Typography>
                  <Typography>
                    {t(
                      "This error monitoring is invaluable for identifying and resolving issues ..."
                    )}
                  </Typography>
                </Stack>
                <ConsentToCollectSentryDataCheckbox
                  value={values.consentToMonitorIssues}
                  onChange={(value) =>
                    setFieldValue("consentToMonitorIssues", value)
                  }
                />
                {values.consentToMonitorIssues && (
                  <AnonymizeIssueMonitoringDataCheckbox
                    value={values.anonymizeIssueMonitoringData}
                    onChange={(value) =>
                      setFieldValue("anonymizeIssueMonitoringData", value)
                    }
                  />
                )}
                <Button variant={"contained"} onClick={() => submitForm()}>
                  {t("Submit")}
                </Button>
              </Stack>
            );
          }}
        </Formik>
      </NavigationLessAppPage>
    );
  }
  return props.children;
};

type SentryConsentForm = {
  consentToMonitorIssues: boolean;
  anonymizeIssueMonitoringData: boolean;
};

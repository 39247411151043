import React, { FC, ReactNode, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSentryFeedback } from "./useSentryFeedback";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import { useUser } from "shared-ts-utils-authentication";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import { useSxMerge } from "shared-ts-mui";

export interface SentryUserFeedbackFormProps {
  sx?: SxProps;
}

export const SentryUserFeedbackForm: FC<SentryUserFeedbackFormProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-sentry");
  const user = useUser();
  const userFeedback = useSentryFeedback();
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const sentiments = useMemo(() => {
    return [
      { id: "happy", icon: <SentimentSatisfiedOutlinedIcon /> },
      { id: "neutral", icon: <SentimentNeutralOutlinedIcon /> },
      { id: "unhappy", icon: <SentimentDissatisfiedOutlinedIcon /> },
    ] as { id: string; icon: ReactNode }[];
  }, []);

  const categories = useMemo(() => {
    return [
      {
        id: "feedback.bug-issue",
        label: t("feedback.bug-issue"),
        description: t("feedback.bug-issue.description"),
        start: t("feedback.bug-issue.start"),
      },
      {
        id: "feedback.usability-issue",
        label: t("feedback.usability-issue"),
        description: t("feedback.usability-issue.description"),
        start: t("feedback.usability-issue.start"),
      },
      {
        id: "feedback.feature-request",
        label: t("feedback.feature-request"),
        description: t("feedback.feature-request.description"),
        start: t("feedback.feature-request.start"),
      },
      {
        id: "feedback.other",
        label: t("feedback.other"),
        description: null,
        start: t("feedback.other.start"),
      },
    ] as Array<{
      id: string;
      label: string;
      description: string | null;
      start: string | null;
    }>;
  }, [t]);

  const handleConsentFormSubmit = (values: SentryConsentForm) => {
    const feedback = {
      sentiment: values.sentiment ?? "neutral",
      category: values.category ?? "feedback-general",
      message: values.message ?? "",
      name: values.anonymous ? "Anonymous" : user.name,
      email: values.anonymous ? "Anonymous" : user.email,
    };

    userFeedback.send(feedback);
    setSubmitted(true);
  };

  const formik = useFormik<SentryConsentForm>({
    initialValues: {
      sentiment: null,
      category: null,
      message: "",
      anonymous: false,
    },
    onSubmit: (values, { resetForm }) => {
      handleConsentFormSubmit(values);
      resetForm();
    },
  });

  const sx = useSxMerge(props.sx, { pt: 2 });

  return (
    <Box sx={sx}>
      {submitted && (
        <>
          <Alert icon={<CheckOutlined fontSize="inherit" />} severity="success">
            {t(
              "Thank you! Your feedback will contribute to making our product better."
            )}
          </Alert>
          <Toolbar>
            <Button variant="contained" onClick={() => setSubmitted(false)}>
              {t("New Feedback")}
            </Button>
          </Toolbar>
        </>
      )}
      {!submitted && (
        <form onSubmit={formik.handleSubmit}>
          <Stack useFlexGap gap={2}>
            <FormControl fullWidth>
              <FormLabel sx={{ mb: 2 }}>
                {t("What would you like to provide feedback on?")}
              </FormLabel>
              <RadioGroup
                value={formik.values.category}
                onChange={(event) =>
                  formik.setFieldValue("category", event.target.value)
                }
              >
                {categories.map((category) => (
                  <FormControlLabel
                    key={category.id}
                    label={
                      <Typography sx={{ alignSelf: "center" }}>
                        <strong>{category.label}</strong>{" "}
                        {category.description != null && (
                          <>: {category.description}</>
                        )}
                      </Typography>
                    }
                    value={category.id}
                    control={<Radio />}
                    sx={{ alignItems: "start" }}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Typography>
              {`${
                categories.find((it) => it.id === formik.values.category)
                  ?.start ?? ""
              }`}
            </Typography>
            <TextField
              variant={"outlined"}
              label={t("Description")}
              multiline
              rows={10}
              fullWidth
              value={formik.values.message}
              onChange={(event) =>
                formik.setFieldValue("message", event.target.value)
              }
            />

            <Typography>
              {t("How satisfied are you with the application?")}
            </Typography>

            <ToggleButtonGroup
              exclusive
              size={"large"}
              value={formik.values.sentiment}
              onChange={(_, value) => formik.setFieldValue("sentiment", value)}
            >
              {sentiments.map((sentiment) => {
                return (
                  <ToggleButton key={sentiment.id} value={sentiment.id}>
                    {sentiment.icon}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>

            <FormControlLabel
              control={<Checkbox value={formik.values.anonymous} />}
              label={t("I want to submit this anonymously")}
            />

            <Toolbar disableGutters variant={dense ? "dense" : "regular"}>
              <Button
                disabled={!(formik.values.category && formik.values.message)}
                variant={"contained"}
                size={componentSize}
                type={"submit"}
              >
                {t("Submit")}
              </Button>
            </Toolbar>
          </Stack>
        </form>
      )}
    </Box>
  );
};

type SentryConsentForm = {
  sentiment: string | null;
  category: string | null;
  message: string;
  anonymous: boolean;
};

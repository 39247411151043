import { cloneElement, FC, ReactNode } from "react";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useLanguage } from "shared-ts-language";
import { Trans, useTranslation } from "react-i18next";
import { useConsentToCollectUsageDataWithNullAsDefault } from "./useConsentToCollectUsageDataWithNullAsDefault";
import { Formik } from "formik";
import { ConsentToCollectUsageDataCheckbox } from "./ConsentToCollectUsageDataCheckbox";
import { AnonymizeUsageDataCheckbox } from "./AnonymizeUsageDataCheckbox";
import { useAnonymizeUsageDataWithNullAsDefault } from "./useAnonymizeUsageDataWithNullAsDefault";
import { PostHogUtils } from "./PostHogUtils";
import { usePostHog } from "posthog-js/react";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";

export interface PostHogConsentProviderProps {
  children: ReactNode;
}

export const PostHogConsentProvider: FC<PostHogConsentProviderProps> = (
  props
) => {
  const { t } = useTranslation("shared-ts-posthog");
  const postHog = usePostHog();
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const [consentToCollectUsageData, setConsentToCollectUsageData] =
    useConsentToCollectUsageDataWithNullAsDefault();
  const [, setAnonymizeUsageData] = useAnonymizeUsageDataWithNullAsDefault();

  const handleConsentFormSubmit = (values: PostHogConsentForm) => {
    if (values.consentToCollectUsageData) {
      setAnonymizeUsageData(values.anonymizeUsageData);
    } else {
      setAnonymizeUsageData(null);
    }
    setConsentToCollectUsageData(values.consentToCollectUsageData);

    new PostHogUtils(postHog).handleConsentChange(
      values.consentToCollectUsageData,
      values.anonymizeUsageData
    );
  };

  if (consentToCollectUsageData == null) {
    return (
      <NavigationLessAppPage
        appName={appInfo.name}
        appIcon={notUndef(appInfo.icon?.large, (it) =>
          cloneElement(it, { style: { maxHeight: "100px" } })
        )}
        flexGrowHeader={"auto"}
        language={language}
        onLanguageChange={setLanguage}
        themeName={themeName}
        onThemeChange={setTheme}
        disableScrollY
        sx={{ pt: 2, pb: 7 }}
      >
        <Formik<PostHogConsentForm>
          initialValues={{
            consentToCollectUsageData: true,
            anonymizeUsageData: false,
          }}
          onSubmit={handleConsentFormSubmit}
        >
          {({ values, setFieldValue, submitForm }) => {
            return (
              <Stack useFlexGap gap={2} sx={{ flexGrow: 1, minHeight: 0 }}>
                <Stack
                  useFlexGap
                  gap={2}
                  sx={{ flexGrow: 1, minHeight: 0, overflowY: "auto" }}
                >
                  <Typography>
                    <Trans
                      i18nKey={"airmont uses PostHog to capture..."}
                      ns={"shared-ts-posthog"}
                      values={{ ApplicationName: appInfo.name }}
                      components={{
                        Link: (
                          <Link
                            href={"https://www.posthog.com"}
                            target={"_blank"}
                          />
                        ),
                      }}
                    />
                  </Typography>
                  <Typography>{t("PostHog stores usage data ...")}</Typography>
                  <Typography>{t("We understand no consent ...")}</Typography>
                </Stack>
                <ConsentToCollectUsageDataCheckbox
                  value={values.consentToCollectUsageData}
                  onChange={(value) =>
                    setFieldValue("consentToCollectUsageData", value)
                  }
                />
                {values.consentToCollectUsageData && (
                  <AnonymizeUsageDataCheckbox
                    value={values.anonymizeUsageData}
                    onChange={(value) =>
                      setFieldValue("anonymizeUsageData", value)
                    }
                  />
                )}
                <Button variant={"contained"} onClick={() => submitForm()}>
                  {t("Submit")}
                </Button>
              </Stack>
            );
          }}
        </Formik>
      </NavigationLessAppPage>
    );
  }
  return props.children;
};

type PostHogConsentForm = {
  consentToCollectUsageData: boolean;
  anonymizeUsageData: boolean;
};

import { StringUtils } from "./StringUtils";

declare global {
  interface String {
    isEmpty(): boolean;

    isNotEmpty(): boolean;

    isBlank(): boolean;

    isNotBlank(): boolean;

    notEmptyOrNull(): string | null;

    notEmptyOrUndef(): string | undefined;

    capitalizeFirstLetter(): string;

    emptyToNull(): string | null;

    hasNonAlphanumeric(): boolean;
  }
}

if (!String.prototype.isEmpty) {
  String.prototype.isEmpty = function (this) {
    return StringUtils.isEmpty(this.toString());
  };
}

if (!String.prototype.isNotEmpty) {
  String.prototype.isNotEmpty = function (this) {
    return StringUtils.isNotEmpty(this.toString());
  };
}

if (!String.prototype.isBlank) {
  String.prototype.isBlank = function (this) {
    return StringUtils.isBlank(this.toString());
  };
}

if (!String.prototype.isNotBlank) {
  String.prototype.isNotBlank = function (this) {
    return StringUtils.isNotBlank(this.toString());
  };
}

if (!String.prototype.notEmptyOrNull) {
  String.prototype.notEmptyOrNull = function (this) {
    return StringUtils.notEmptyOrNull(this.toString());
  };
}

if (!String.prototype.notEmptyOrUndef) {
  String.prototype.notEmptyOrUndef = function (this) {
    return StringUtils.notEmptyOrUndef(this.toString());
  };
}

if (!String.prototype.capitalizeFirstLetter) {
  String.prototype.capitalizeFirstLetter = function (this: string) {
    return StringUtils.capitalizeFirstLetter(this);
  };
}

if (!String.prototype.emptyToNull) {
  String.prototype.emptyToNull = function (this) {
    return StringUtils.emptyToNull(this.toString());
  };
}

if (!String.prototype.hasNonAlphanumeric) {
  String.prototype.hasNonAlphanumeric = function (this) {
    return StringUtils.hasNonAlphanumeric(this.toString());
  };
}

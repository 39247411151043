import { FC, ReactNode, useEffect } from "react";
import { useAppTheme } from "./AppThemeProvider";
import { useThemeUserSetting } from "./useThemeUserSetting";

export const AppThemeInitializer: FC<{ children: ReactNode }> = (props) => {
  const { themeName, setTheme } = useAppTheme();
  const [theme] = useThemeUserSetting();

  useEffect(() => {
    if (themeName !== theme) {
      setTheme(theme);
    }
  }, [theme, themeName, setTheme]);

  return props.children;
};

import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Stack,
  Toolbar,
} from "@mui/material";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";
import {
  OidcUser,
  useOidcPaths,
} from "@airmont/firefly/shared/ts/authentication-oidc";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { useUser } from "shared-ts-utils-authentication";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Property } from "shared-ts-property";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { LanguageProperty, useLanguage } from "shared-ts-language";
import {
  AnonymizeUsageDataProperty,
  ConsentToCollectUsageDataProperty,
  useAnonymizeUsageData,
  useConsentToCollectUsageData,
} from "shared-ts-posthog";
import {
  AnonymizeIssueMonitoringDataProperty,
  ConsentToMonitorIssuesProperty,
  useAnonymizeIssueMonitoringData,
  useConsentToMonitorIssues,
} from "shared-ts-sentry";
import { useNavigate } from "react-router";

export const UserPage: FC = () => {
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const navigate = useNavigate();
  const [consentToMonitorIssues, setConsentToMonitorIssues] =
    useConsentToMonitorIssues();
  const [anonymizeIssueMonitoringData, setAnonymizeIssueMonitoringData] =
    useAnonymizeIssueMonitoringData();
  const [consentToCollectUsageData, setConsentToCollectUsageData] =
    useConsentToCollectUsageData();
  const [anonymizeUsageData, setAnonymizeUsageData] = useAnonymizeUsageData();
  const width = useWindowWidth();
  const oidcPaths = useOidcPaths();
  const user = useUser() as OidcUser;
  const userHref = oidcPaths?.IdentityManagePath;
  const { language, setLanguage } = useLanguage();

  const initialFormValues: UserFormValues = {
    language: language,
    consentToMonitorIssues: consentToMonitorIssues,
    anonymizeIssueMonitoringData: anonymizeIssueMonitoringData,
    consentToCollectUsageData: consentToCollectUsageData,
    anonymizeUsageData: anonymizeUsageData,
  };

  const handleSubmit = (values: UserFormValues) => {
    setLanguage(values.language);
    setConsentToMonitorIssues(values.consentToMonitorIssues);
    setAnonymizeIssueMonitoringData(values.anonymizeIssueMonitoringData);
    setConsentToCollectUsageData(values.consentToCollectUsageData);
    setAnonymizeUsageData(values.anonymizeUsageData);

    /* Google maps needs refresh when changing language,
    same does PostHog and Sentry when their settings are changed
    */
    if (
      values.language !== language ||
      values.consentToMonitorIssues !== consentToMonitorIssues ||
      values.anonymizeIssueMonitoringData !== anonymizeIssueMonitoringData ||
      values.consentToCollectUsageData !== consentToCollectUsageData ||
      values.anonymizeUsageData !== anonymizeUsageData
    ) {
      navigate(0);
    }
    return Promise.resolve();
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <AppPage
            $key={"UserPage"}
            name={t("User")}
            mainPaneProps={{
              scrollableContentVertically: true,
            }}
            mainPane={
              <Box>
                <Container
                  maxWidth={
                    width === SizeClass.Compact
                      ? "xs"
                      : width === SizeClass.Medium
                      ? "xs"
                      : "xs"
                  }
                >
                  <Card variant={"outlined"}>
                    <CardContent>
                      <Stack direction={"column"} sx={{ flex: 1 }}>
                        <PropertiesCard
                          elevation={0}
                          childPropertyProps={{
                            fullWidth: true,
                          }}
                          header={{ title: t("User Details") }}
                          sx={{ flexGrow: 1 }}
                        >
                          <Property
                            label={t("Username")}
                            value={user.name}
                            mode={"read"}
                          />
                          <Property
                            name={"email"}
                            label={t("Email")}
                            value={user.email}
                            mode={"read"}
                          />
                          <Property
                            name={"role"}
                            label={t("Role")}
                            value={user.getRole()}
                            mode={"read"}
                          />
                        </PropertiesCard>
                        <Divider />
                        <Toolbar variant={"dense"} sx={{ gap: 1 }}>
                          <Link
                            href={userHref}
                            target={"_blank"}
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            {t("Edit User or Logout")}
                            <OpenInNewRoundedIcon />
                          </Link>
                        </Toolbar>
                        <Divider />
                        <PropertiesCard
                          elevation={0}
                          childPropertyProps={{
                            fullWidth: true,
                          }}
                          header={{ title: t("User Settings") }}
                          sx={{ flexGrow: 1 }}
                        >
                          <LanguageProperty
                            value={values.language}
                            onFormikFieldValueChange={setFieldValue}
                            mode={"edit"}
                            fullWidth
                          />
                          <ConsentToMonitorIssuesProperty
                            name={"consentToMonitorIssues"}
                            value={values.consentToMonitorIssues}
                            onFormikFieldValueChange={setFieldValue}
                          />
                          {values.consentToMonitorIssues && (
                            <AnonymizeIssueMonitoringDataProperty
                              name={"anonymizeIssueMonitoringData"}
                              value={values.anonymizeIssueMonitoringData}
                              onFormikFieldValueChange={setFieldValue}
                            />
                          )}
                          <ConsentToCollectUsageDataProperty
                            name={"consentToCollectUsageData"}
                            value={values.consentToCollectUsageData}
                            onFormikFieldValueChange={setFieldValue}
                          />
                          {values.consentToCollectUsageData && (
                            <AnonymizeUsageDataProperty
                              name={"anonymizeUsageData"}
                              value={values.anonymizeUsageData}
                              onFormikFieldValueChange={setFieldValue}
                            />
                          )}
                        </PropertiesCard>
                        <Toolbar variant={"dense"} sx={{ gap: 1 }}>
                          <LoadingButton
                            loading={isSubmitting}
                            disabled={!dirty}
                            onClick={() => submitForm()}
                          >
                            {t("Save")}
                          </LoadingButton>
                        </Toolbar>
                      </Stack>
                    </CardContent>
                  </Card>
                </Container>
              </Box>
            }
          />
        );
      }}
    </Formik>
  );
};

export interface UserFormValues {
  language: string;
  consentToMonitorIssues: boolean;
  anonymizeIssueMonitoringData: boolean;
  consentToCollectUsageData: boolean;
  anonymizeUsageData: boolean;
}

import { FC, ReactNode, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  isDevelopmentEnvironment,
  ServerEnvironment,
  useResolveServerEnvironmentFromWindowLocation,
} from "shared-ts-server-environment";
import { useConsentToMonitorIssues } from "./useConsentToMonitorIssues";
import { User, useUser } from "shared-ts-utils-authentication";
import { useAnonymizeIssueMonitoringData } from "./useAnonymizeIssueMonitoringData";
import { AppInfo, useAppInfo } from "shared-ts-app-info";
import type { Integration } from "@sentry/types/build/types/integration";

let initialized = false;

export interface SentryInitializerProps {
  dsn: string;
  feedbackIntegration?: boolean;
  children: ReactNode;
}

export const SentryInitializer: FC<SentryInitializerProps> = (props) => {
  const serverEnvironment = useResolveServerEnvironmentFromWindowLocation();
  const appInfo = useAppInfo();
  const user = useUser();
  const [consent] = useConsentToMonitorIssues();
  const [anonymize] = useAnonymizeIssueMonitoringData();
  const enabled =
    (serverEnvironment !== "local" ||
      localStorage.getItem("Sentry.enabled") === "true") &&
    consent;

  useEffect(() => {
    if (!initialized) {
      // Preventing initializing twice (happens when running in React StrictMode) as Sentry does not like this
      initializeSentry({
        dsn: props.dsn,
        enabled: enabled,
        environment: serverEnvironment,
        appInfo: appInfo,
        user: user,
        anonymize: anonymize,
        feedbackIntegration: props.feedbackIntegration,
      });
      initialized = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children;
};

const initializeSentry = (args: {
  dsn: string;
  enabled: boolean;
  environment: ServerEnvironment;
  appInfo: AppInfo;
  user: User;
  anonymize: boolean;
  feedbackIntegration?: boolean;
}) => {
  const integrations: Integration[] = [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ];

  if (args.feedbackIntegration === true) {
    integrations.push(
      Sentry.feedbackIntegration({
        colorScheme: "system",
        autoInject: false,
      })
    );
  }

  Sentry.init({
    enabled: args.enabled,
    dsn: args.dsn,
    environment: args.environment,
    release: args.appInfo.buildVersion,
    beforeSend(event) {
      if (args.anonymize) {
        delete event.user?.ip_address;
      }
      return event;
    },
    integrations: integrations,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: isDevelopmentEnvironment(args.environment)
      ? 1
      : 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.setTags({
    appRelease: args.appInfo.release,
    appBuildVersion: args.appInfo.buildVersion,
  });

  if (!args.anonymize) {
    Sentry.setUser({
      id: args.user.id,
      username: args.user.name,
      email: args.user.email,
    });
  }
};

import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export const useThemeUserSettingWithDefault = (defaultTheme: string) => {
  return useUserSettingWithDefault("theme", StringSetting, defaultTheme, {
    storeLocally: true,
    storeCopyToAppLocally: true,
  });
};

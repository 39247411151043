import * as Sentry from "@sentry/browser";
import type { SendFeedbackParams } from "@sentry/types";

export interface ISentryUserFeedback {
  name: string;
  email: string;
  sentiment: string;
  category: string;
  message: string;
  url?: string;
  source?: string;
  captureEvent?: boolean;
}

export interface ISentryFeedbackHandler {
  send(feedback: ISentryUserFeedback): void;
}

export const useSentryFeedback = () => {
  return {
    send: (userFeedback: ISentryUserFeedback) => {
      const eventId = userFeedback.captureEvent
        ? Sentry.lastEventId()
        : Sentry.captureMessage("User Feedback");

      const userFeedbackWithEventId: SendFeedbackParams = {
        ...userFeedback,
        associatedEventId: eventId,
        tags: {
          sentiment: userFeedback.sentiment,
          category: userFeedback.category,
        },
      };
      Sentry.captureFeedback(userFeedbackWithEventId, {});
    },
  } as ISentryFeedbackHandler;
};

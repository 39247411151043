import { FetchError } from "./FetchError";
import { notOKToError } from "./notOKToError";
import { ServerErrorFactory } from "./ServerErrorFactory";
import { merge } from "lodash";
import { JSONValue } from "./Json";
import { defaultServerErrorFactory } from "./defaultServerErrorFactory";

type ResponseType =
  | "json"
  | "text"
  | "form"
  | "blob"
  | "arraybuffer"
  | "Response"
  | "void";

const responseTypeToContentType = (value: ResponseType): string | undefined => {
  if (value === "json") {
    return "application/json";
  } else if (value === "text") {
    return "text/plain";
  } else {
    return undefined;
  }
};

export interface FetchBuilderOptions {
  request: Request;
  responseType?: ResponseType;
  serverErrorFactory: ServerErrorFactory | null;
  transformerFn?: (responseValue: unknown) => unknown;
  signal?: AbortSignal;
}

export interface FetchHelperOptions {
  signal?: AbortSignal;
}

export class FetchBuilder {
  static defaultServerErrorFactory: ServerErrorFactory =
    defaultServerErrorFactory;
  private readonly request: Request;
  private readonly serverErrorFactory: ServerErrorFactory;
  private transformerFn?: (responseValue: unknown) => unknown;
  private responseType: ResponseType;
  private readonly signal?: AbortSignal;
  private headers?: Headers;

  constructor(options: FetchBuilderOptions) {
    this.request = options.request;
    this.serverErrorFactory =
      options.serverErrorFactory || FetchBuilder.defaultServerErrorFactory;
    this.responseType = options.responseType ?? "void";
    this.signal = options.signal;
  }

  setHeader(name: string, value: string): FetchBuilder {
    if (this.headers == null) {
      this.headers = new Headers();
    }
    this.headers.set(name, value);
    return this;
  }

  transformer<I = unknown, T = unknown>(fn: (response: I) => T): FetchBuilder {
    this.transformerFn = fn as (responseValue: unknown) => T;
    return this;
  }

  fetch(): Promise<void> {
    const headersInit: HeadersInit = {};
    this.request.headers.forEach((value, key) => {
      headersInit[key] = value;
    });
    const contentType = responseTypeToContentType(this.responseType);
    if (contentType != null) {
      headersInit["content-type"] = contentType;
    }

    const init: RequestInit = {
      headers: headersInit,
      signal: this.signal,
    };
    const request = new Request(this.request, init);
    return this._fetch(request, true);
  }

  fetchAsResponse(args?: {
    contentType?: string;
    ignoreError?: boolean;
  }): Promise<Response> {
    this.responseType = "Response";
    const headersInit: HeadersInit = {};
    this.request.headers.forEach((value, key) => {
      headersInit[key] = value;
    });
    if (args?.contentType !== undefined) {
      headersInit["content-type"] = args.contentType;
    }

    const init: RequestInit = {
      headers: headersInit,
      signal: this.signal,
    };
    const request = new Request(this.request, init);
    return this._fetch(request, false, args?.ignoreError ?? false);
  }

  fetchAsArrayBuffer(): Promise<File> {
    this.responseType = "arraybuffer";
    const signal = this.signal;
    const request = new Request(this.request, { signal });
    return this._fetch(request);
  }

  fetchAsBlob(): Promise<File> {
    this.responseType = "blob";
    const signal = this.signal;
    const request = new Request(this.request, { signal });
    return this._fetch(request);
  }

  fetchAsText(): Promise<string> {
    this.responseType = "text";

    const headersInit: HeadersInit = {};
    this.request.headers.forEach((value, key) => {
      headersInit[key] = value;
    });
    headersInit["content-type"] = "text/plain";
    const init: RequestInit = {
      headers: headersInit,
      signal: this.signal,
    };
    const request = new Request(this.request, init);
    return this._fetch(request);
  }

  fetchAsJson<T>(): Promise<T> {
    this.responseType = "json";

    const headersInit: HeadersInit = {};
    this.request.headers.forEach((value, key) => {
      headersInit[key] = value;
    });
    this.headers?.forEach((value, key) => {
      headersInit[key] = value;
    });

    headersInit["content-type"] = "application/json";

    const init: RequestInit = {
      headers: headersInit,
      signal: this.signal,
    };
    const request = new Request(this.request, init);
    return this._fetch(request);
  }

  private _fetch<T>(
    request: Request,
    ignoreResponse = false,
    ignoreError = false
  ): Promise<T> {
    return fetch(request, { signal: this.signal })
      .then(
        (response: Response) => {
          return ignoreError
            ? response
            : notOKToError(request, response, this.serverErrorFactory);
        },
        (error) => {
          throw new FetchError({ request: this.request, cause: error });
        }
      )
      .then((response: Response) => {
        if (ignoreResponse || response.status === 204) {
          return;
        } else if (this.responseType === "Response") {
          return response;
        } else if (this.responseType === "json") {
          return response.json();
        } else if (this.responseType === "text") {
          return response.text();
        } else if (this.responseType === "blob") {
          return response.blob();
        } else if (this.responseType === "form") {
          return response.formData();
        } else if (this.responseType === "arraybuffer") {
          return response.arrayBuffer();
        } else {
          return;
        }
      })
      .then((json) => {
        if (this.transformerFn != null) {
          return this.transformerFn(json);
        } else {
          return json;
        }
      });
  }
}

export class PostBuilder extends FetchBuilder {}

export class PutBuilder extends FetchBuilder {}

export class DeleteBuilder extends FetchBuilder {}

export class FetchHelper {
  private readonly init: RequestInit;
  private readonly serverErrorFactory: ServerErrorFactory | null;
  private readonly basePath: string | null;

  constructor(options?: {
    init?: RequestInit;
    basePath?: string;
    serverErrorFactory?: ServerErrorFactory;
  }) {
    this.init = options?.init ?? {};
    this.serverErrorFactory = options?.serverErrorFactory || null;
    this.basePath = options?.basePath || null;
  }

  addHeader(name: string, value: string): FetchHelper {
    this.init.headers = merge(this.init.headers, {
      [name]: value,
    });
    return this;
  }

  authorizationBearer(accessToken: string | undefined): FetchHelper {
    if (accessToken != null) {
      this.init.headers = merge(this.init.headers, {
        Authorization: `Bearer ${accessToken}`,
      });
    }
    return this;
  }

  get(
    path: string,
    queryParams:
      | Record<string, string>
      | URLSearchParams
      | undefined = undefined,
    options?: FetchHelperOptions
  ): FetchBuilder {
    const queryParamsAsString =
      queryParams != null
        ? queryParams instanceof URLSearchParams
          ? queryParams.toString()
          : new URLSearchParams(queryParams).toString()
        : null;

    const url =
      this.applyBasePath(path) +
      (queryParams != null ? "?" + queryParamsAsString : "");
    const request = new Request(url, {
      method: "GET",
      headers: this.init.headers,
    });

    return new FetchBuilder({
      request: request,
      serverErrorFactory: this.serverErrorFactory,
      signal: options?.signal,
    });
  }

  post(
    path: string,
    json?: JSONValue,
    options?: FetchHelperOptions
  ): FetchBuilder {
    const url = this.applyBasePath(path);
    const request = new Request(url, {
      method: "POST",
      headers: this.init.headers,
      body: json != null ? JSON.stringify(json) : undefined,
    });
    return new PostBuilder({
      request: request,
      serverErrorFactory: this.serverErrorFactory,
      signal: options?.signal,
      responseType: json != null ? "json" : undefined,
    });
  }

  put(
    path: string,
    json?: JSONValue,
    options?: FetchHelperOptions
  ): FetchBuilder {
    const url = this.applyBasePath(path);
    const request = new Request(url, {
      method: "PUT",
      headers: this.init.headers,
      body: json != null ? JSON.stringify(json) : undefined,
    });

    request.headers.forEach((value, key) => {
      console.log(`FetchHelper.put header: ${key} : ${value}`);
    });

    return new PutBuilder({
      request: request,
      serverErrorFactory: this.serverErrorFactory,
      signal: options?.signal,
      responseType: json != null ? "json" : undefined,
    });
  }

  delete(
    path: string,
    json?: JSONValue,
    options?: FetchHelperOptions
  ): FetchBuilder {
    const url = this.applyBasePath(path);
    const request = new Request(url, {
      method: "DELETE",
      headers: this.init.headers,
      body: json != null ? JSON.stringify(json) : undefined,
    });

    return new DeleteBuilder({
      request: request,
      serverErrorFactory: this.serverErrorFactory,
      signal: options?.signal,
      responseType: json != null ? "json" : undefined,
    });
  }

  private applyBasePath(request: string): string {
    if (this.basePath != null) {
      return this.basePath + request;
    } else {
      return request;
    }
  }
}

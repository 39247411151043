import React, { cloneElement, FC, ReactElement, ReactNode } from "react";
import { Box, Container, Stack, SxProps, Typography } from "@mui/material";
import {
  SizeClass,
  useWindowHeightComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { LanguageIconButtonMenu } from "shared-ts-language";
import { useSxMerge } from "shared-ts-mui";
import { ThemeToggleButton } from "@airmont/shared/ts/ui/app-theme-provider";

export interface NavigationLessAppPageProps {
  appIcon?: ReactNode;
  appName?: ReactNode;
  children: ReactNode;
  language?: string;
  themeName?: string;
  disableScrollY?: boolean;
  onLanguageChange?: (lang: string) => void;
  onThemeChange?: React.Dispatch<string>;
  sx?: SxProps;
  flexGrowHeader?: string | number;
  flexGrowContent?: string | number;
}

export const NavigationLessAppPage: FC<NavigationLessAppPageProps> = (
  props
) => {
  const disableScrollY = props.disableScrollY ?? false;
  const widthComparer = useWindowWidthComparer();
  const heightComparer = useWindowHeightComparer();
  const sizeComparer = heightComparer.lt(widthComparer.sizeClass)
    ? heightComparer
    : widthComparer;
  const childrenIsValidReactElement = React.isValidElement(props.children);
  const sx = useSxMerge(props.sx, {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  });
  return (
    <Container
      className={"NavigationLessAppPage"}
      maxWidth={
        sizeComparer.gte(SizeClass.Expanded)
          ? "md"
          : sizeComparer.gte(SizeClass.Medium)
          ? "md"
          : "xs"
      }
      component={"main"}
      sx={sx}
    >
      <Box
        className={"NavigationLessAppPage-height"}
        gap={3}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: "grid",
          gridTemplateRows: `${
            props.flexGrowHeader != null ? props.flexGrowHeader : "1fr"
          } ${
            props.flexGrowContent != null ? props.flexGrowContent : "1.618fr"
          }`,
        }}
      >
        <Stack
          className={"NavigationLessAppPage-header"}
          direction={"column"}
          useFlexGap
          gap={1}
          sx={{
            justifyContent: "end",
            minHeight: 0,
            pt: 1,
          }}
        >
          {props.appIcon != null && props.appIcon}
          {React.isValidElement(props.appName) && props.appName}
          {props.appName != null && typeof props.appName === "string" && (
            <Typography
              align={"center"}
              variant={
                sizeComparer.lte(SizeClass.Compact)
                  ? "h4"
                  : sizeComparer.lte(SizeClass.Medium)
                  ? "h3"
                  : sizeComparer.lte(SizeClass.Expanded)
                  ? "h2"
                  : "h1"
              }
              sx={{ fontWeight: 500 }}
            >
              {props.appName}
            </Typography>
          )}
        </Stack>
        <Stack
          className={"NavigationLessAppPage-content"}
          sx={{
            minHeight: 0,
            overflowY: !disableScrollY ? "auto" : undefined,
            justifyContent: "start",
          }}
        >
          {childrenIsValidReactElement &&
            cloneElement<{ sx?: SxProps }>(props.children as ReactElement, {
              sx: {
                flexGrow: 1,
                minHeight: 0,
              },
            })}
          {!childrenIsValidReactElement && props.children}
        </Stack>
      </Box>
      {props.themeName != null && props.onThemeChange != null && (
        <Box sx={{ position: "fixed", left: "10px", bottom: "10px" }}>
          <ThemeToggleButton
            value={props.themeName}
            onChange={props.onThemeChange}
          />
        </Box>
      )}
      {props.language != null && props.onLanguageChange != null && (
        <Box sx={{ position: "fixed", right: "10px", bottom: "10px" }}>
          <LanguageIconButtonMenu
            label={`Språk / Language`}
            value={props.language}
            onChange={props.onLanguageChange}
          />
        </Box>
      )}
    </Container>
  );
};

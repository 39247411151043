import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SettingsContextDictionary,
  UserSettingsContext,
} from "./UserSettingsContext";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppInfo } from "shared-ts-app-info";
import { SettingsStorage } from "./storage/SettingsStorage";
import { LocalSettingsStorage } from "./storage/LocalSettingsStorage";
import { AsyncSettingsStorage } from "./storage/AsyncSettingsStorage";
import { ReadOnlyUserSettings } from "./ReadOnlyUserSettings";

export interface UserSettingsProviderProps {
  storage?: SettingsStorage;
  loadingComponent?: ReactNode;
  children: ReactNode | ((settings: ReadOnlyUserSettings) => ReactNode);
}

export const UserSettingsProviderAsync: FC<UserSettingsProviderProps> = (
  props
) => {
  const user = useUserOrNull();
  const userId = user?.id;
  const app = useAppInfo();
  const appKey = app.key;

  const [entries, setEntries] = useState<SettingsContextDictionary | undefined>(
    undefined
  );
  const storage = props.storage ?? LocalSettingsStorage;
  useEffect(() => {
    const doEffect = async () => {
      const loadedEntries: SettingsContextDictionary = {};
      const asyncStorage = storage as AsyncSettingsStorage;
      const userSettingDtos = await asyncStorage.loadSettings(appKey, userId);
      userSettingDtos
        .filter((it) => it.user === it.app)
        .forEach((userSetting) => {
          const key = `${userSetting.app}:${userSetting.user}:${userSetting.name}`;
          loadedEntries[key] = userSetting.value;
        });
      userSettingDtos
        .filter((it) => it.user !== it.app)
        .forEach((userSetting) => {
          const key = `${userSetting.app}:${userSetting.user}:${userSetting.name}`;
          loadedEntries[key] = userSetting.value;
        });

      setEntries(loadedEntries);
      console.debug(
        `UserSettingsProvider loaded the following settings for user : ${userId}):`
      );
      Object.entries(loadedEntries).forEach(([key, value]) => {
        const debugKey = key.substring(key.lastIndexOf(":") + 1);
        console.debug(`${debugKey}: ${value}`);
      });
    };
    doEffect();
  }, []);

  const readOnlySettings = useMemo(() => {
    if (entries == null || user == null) {
      return undefined;
    }
    return new ReadOnlyUserSettings({
      app: app.key,
      user: user,
      settings: entries,
    });
  }, [app.key, user, entries]);

  if (entries === undefined || readOnlySettings == null) {
    return props.loadingComponent ?? null;
  }

  return (
    <UserSettingsContext.Provider
      value={{
        settings: entries,
        setSettings: setEntries as Dispatch<
          SetStateAction<SettingsContextDictionary>
        >,
        storage: storage,
        localStorage: LocalSettingsStorage,
      }}
    >
      {typeof props.children === "function"
        ? props.children(readOnlySettings)
        : props.children}
    </UserSettingsContext.Provider>
  );
};

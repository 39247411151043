import { PropertyEditDate } from "./PropertyEditDate";
import {
  _throw,
  IllegalArgumentError,
  IllegalStateError,
  RangeDto,
} from "@airmont/shared/ts/utils/core";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { PropertyEditSlider } from "./PropertyEditSlider";
import { PropertyEditDateTime } from "./PropertyEditDateTime";
import { PropertyEditFile } from "./PropertyEditFile";
import { PropertyEditAutocompleteMultiple } from "./PropertyEditAutocompleteMultiple";
import { PropertyEditAutocomplete } from "./PropertyEditAutocomplete";
import { PropertyEditString } from "./PropertyEditString";
import React from "react";
import { PropertyEditProps } from "./PropertyEdit";
import { EditComponentTypeName } from "./EditComponentTypeName";
import { PropertyEditBoolean } from "./PropertyEditBoolean";
import { PropertyEditSelect } from "./PropertyEditSelect";

export const resolveEditComponent = (
  props: PropertyEditProps,
  editComponent: EditComponentTypeName
): JSX.Element => {
  if (editComponent === "Switch") {
    return (
      <PropertyEditBoolean
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as boolean | undefined}
        removable={props.removable}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        inputRef={props.inputRef}
        sx={props.sx}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "Date") {
    return (
      <PropertyEditDate
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        disableFuture={props.disableFuture}
        value={props.value as DateTimeISO}
        removable={props.removable}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        sx={props.sx}
        inputRef={props.inputRef}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "Slider") {
    return (
      <PropertyEditSlider
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as RangeDto}
        removable={props.removable}
        onChange={props.onChange}
        onBlur={props.onBlur}
        size={props.size}
        fullWidth={props.fullWidth}
        componentProps={props.editComponentProps?.Slider}
        unit={props.unit}
        sx={props.sx}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "Select") {
    return (
      <PropertyEditSelect
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as number | string}
        valueOptions={props.valueOptions}
        removable={props.removable}
        onChange={props.onChange}
        onBlur={props.onBlur}
        size={props.size}
        fullWidth={props.fullWidth}
        //componentProps={props.editComponentProps?.Slider}
        //unit={props.unit}
        sx={props.sx}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "DateTime") {
    return (
      <PropertyEditDateTime
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as DateTimeISO}
        removable={props.removable}
        disableFuture={props.disableFuture}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        sx={props.sx}
        inputRef={props.inputRef}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "File") {
    return (
      <PropertyEditFile
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as File}
        removable={props.removable}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        inputRef={props.inputRef}
        sx={props.sx}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
      />
    );
  } else if (editComponent === "AutocompleteMultiple") {
    if (props.valueOptions == null) {
      throw new IllegalStateError("valueOptions not set");
    }
    return (
      <PropertyEditAutocompleteMultiple
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value}
        valueOptions={props.valueOptions}
        removable={props.removable}
        onChange={props.onChange}
        onBlur={props.onBlur}
        unit={props.unit}
        type={props.type}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        sx={props.sx}
      />
    );
  } else if (editComponent === "Autocomplete") {
    if (props.valueOptions == null) {
      throw new IllegalStateError("valueOptions not set");
    }
    return (
      <PropertyEditAutocomplete
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value}
        valueOptions={props.valueOptions}
        removable={props.removable}
        onChange={props.onChange}
        onBlur={props.onBlur}
        unit={props.unit}
        type={props.type}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        sx={props.sx}
      />
    );
  } else if (editComponent === "TextField") {
    return (
      <PropertyEditString
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value ?? ""}
        removable={props.removable}
        unit={props.unit}
        size={props.size}
        type={props.type}
        multiline={props.multiline}
        onChange={props.onChange}
        onBlur={(event) => props.onBlur?.(event)}
        fullWidth={props.fullWidth ?? false}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        helperText={props.helperText}
        error={props.error}
        sx={props.sx}
      />
    );
  } else {
    throw new IllegalArgumentError(
      "Unsupported edit component: " + editComponent
    );
  }
};

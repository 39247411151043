import React, { FC, useMemo } from "react";
import { BurnQualityCountAggregateByTime } from "./BurnQualityCountAggregateByTime";
import { Stack, useTheme } from "@mui/material";
import { MuiChartContainer, useSxMerge } from "shared-ts-mui";
import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  LineSeriesType,
  MarkElement,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { SxProps } from "@mui/system";
import { DateTime, DateTimeUnit, Interval } from "luxon";
import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { BurnAssessmentColor } from "./BurnAssessmentColor";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";
import { useTranslation } from "react-i18next";
import { DatasetType } from "@mui/x-charts/models/seriesType/config";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {
  AxisConfig,
  ChartsYAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { BurnQualityCountDataPoints } from "./BurnQualityCountDataPoints";
import { ContinuousColorConfig } from "@mui/x-charts/models/colorMapping";
import { MarkElementProps } from "@mui/x-charts/LineChart/MarkElement";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface BurnQualityCountByTimeChartProps {
  interval: Interval<true>;
  timeUnit: DateTimeUnit;
  thresholdFactor: number;
  aggregates?: Array<BurnQualityCountAggregateByTime>;
  showBurnQualityCount?: boolean;
  showGoodInPercent: boolean;
  showRawGoodInPercent?: boolean;
  loading: boolean;
  hideAverageGoodInPercent?: boolean;
  hideYAxisLabels?: boolean;
  layout?: SizeClass;
  sx?: SxProps;
}

export const BurnQualityCountByTimeChart: FC<
  BurnQualityCountByTimeChartProps
> = (props) => {
  const { interval, timeUnit, loading, aggregates, thresholdFactor } = props;
  const intervalLessThanYear = interval.length("year") <= 1;
  const layout = props.layout ?? SizeClass.ExtraLarge;
  const hideXAxiLabels = layout === SizeClass.Compact;
  const hideYAxisLabels = props.hideYAxisLabels ?? layout === SizeClass.Compact;
  const hideAverageGoodInPercent = props.hideAverageGoodInPercent ?? false;
  const theme = useTheme();
  const { t } = useTranslation("firefly-shared-ts-domain");
  const showIgnitionQualityCount = props.showBurnQualityCount ?? true;
  const showGoodInPercent = props.showGoodInPercent;
  const showRawGoodInPercent = props.showRawGoodInPercent ?? false;
  const monthLabels = useMemo(() => {
    return new TimeWheel({
      start: interval.start as DateTime<true>,
      timeUnit: timeUnit,
    }).runUntilTime(interval.end, (dateTime) => {
      return timeUnit === "week"
        ? dateTime.toFormat("WW")
        : dateTime
            .toFormat(
              intervalLessThanYear
                ? SizeClass.Compact
                  ? "MMM"
                  : "MMMM"
                : layout === SizeClass.Compact
                ? "MMM yy"
                : "MMMM yyyy"
            )
            .capitalizeFirstLetter();
    });
  }, [interval.start, interval.end, timeUnit, intervalLessThanYear, layout]);
  const dataSet: BurnQualityCountDataPoints = useMemo(
    () =>
      aggregates != null
        ? BurnQualityCountDataPoints.fromAggregates(aggregates, {
            thresholdFactor: thresholdFactor,
          }).between(interval, timeUnit)
        : BurnQualityCountDataPoints.empty(),
    [aggregates, interval, thresholdFactor, timeUnit]
  );
  const averageGoodInPercent = useMemo(() => {
    return dataSet.avgGoodIgnitionsInPercent();
  }, [dataSet]);

  const series = useMemo(() => {
    const goodInPercentSeries = {
      id: "goodInPercent",
      yAxisId: "percentage",
      label: t("Good ignitions in %"),
      type: "line",
      dataKey: "goodInPercent",
      connectNulls: true,
    } as LineSeriesType;

    const goodInPercentRawSeries = {
      id: "goodInPercentRaw",
      yAxisId: "percentage",
      label: "Good ignitions in % (raw)",
      type: "line",
      dataKey: "goodInPercentRaw",
      connectNulls: true,
    } as LineSeriesType;

    const excellentSeries = {
      id: "excellent",
      label: t("BurnAssessment.Good"),
      type: "bar",
      stack: "total",
      dataKey: "excellent",
      color: BurnAssessmentColor.Good,
    } as BarSeriesType;

    const goodSeries = {
      id: "good",
      label: t("BurnAssessment.Normal"),
      type: "bar",
      stack: "total",
      dataKey: "good",
      color: BurnAssessmentColor.Normal,
    } as BarSeriesType;

    const badSeries = {
      id: "bad",
      label: t("BurnAssessment.Bad"),
      type: "bar",
      stack: "total",
      dataKey: "bad",
      color: BurnAssessmentColor.Bad,
    } as BarSeriesType;

    const unknownSeries = {
      id: "unknown",
      label: t("BurnAssessment.Unknown"),
      type: "bar",
      stack: "total",
      dataKey: "unknown",
      color: BurnAssessmentColor.Unknown,
    } as BarSeriesType;

    const arrayOfSeries: Array<LineSeriesType | BarSeriesType> = [];

    if (showGoodInPercent) {
      arrayOfSeries.push(goodInPercentSeries);
    }
    if (showGoodInPercent && showRawGoodInPercent) {
      arrayOfSeries.push(goodInPercentRawSeries);
    }

    if (showIgnitionQualityCount) {
      arrayOfSeries.push(unknownSeries);
      arrayOfSeries.push(badSeries);
      arrayOfSeries.push(goodSeries);
      arrayOfSeries.push(excellentSeries);
    }
    return arrayOfSeries;
  }, [t, showGoodInPercent, showRawGoodInPercent, showIgnitionQualityCount]);

  const sx = useSxMerge(props.sx, {});

  const continuousColorMap: ContinuousColorConfig<number> = useMemo(() => {
    return {
      type: "continuous",
      min: 0,
      max: 100,
      color: [BurnAssessmentColor.Bad, BurnAssessmentColor.Good],
    } as ContinuousColorConfig<number>;
  }, []);

  const yAxis = useMemo(() => {
    const yAxis: Array<
      MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">
    > = [];
    if (showIgnitionQualityCount) {
      yAxis.push({
        id: "burnCount",
        scaleType: "linear",
        label: t("Ignitions"),
      } as MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">);
    }
    if (showGoodInPercent) {
      yAxis.push({
        id: "percentage",
        scaleType: "linear",
        label: t("Percentage"),
        valueFormatter: (value) => `${value}%`,
        min: 0,
        max: 100,
        colorMap: !showIgnitionQualityCount ? continuousColorMap : undefined,
      });
    }

    return yAxis;
  }, [showIgnitionQualityCount, showGoodInPercent, t, continuousColorMap]);

  return (
    <Stack direction={"column"} sx={sx}>
      <MuiChartContainer
        loading={loading}
        sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      >
        <ResponsiveChartContainer
          margin={
            layout === SizeClass.Compact
              ? {
                  top: 20,
                  left: 35,
                  right: 40,
                  bottom: showIgnitionQualityCount ? 90 : 30,
                }
              : {
                  top: 20,
                  left: 75,
                  right: 75,
                  bottom: 90,
                }
          }
          dataset={dataSet as unknown as DatasetType}
          series={series}
          xAxis={[
            {
              id: "months",
              data: monthLabels,
              scaleType: "band",
            },
          ]}
          yAxis={yAxis}
          sx={{
            ".MuiMarkElement-root": {
              scale: "1.2",
            },
            ".MuiLineElement-root": {
              strokeWidth: 5,
              filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.9))",
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(-20px)",
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(20px)",
            },
          }}
        >
          {showIgnitionQualityCount && (
            <ChartsLegend
              position={{
                vertical: "bottom",
                horizontal: "middle",
              }}
            />
          )}
          <ChartsXAxis
            label={
              hideXAxiLabels
                ? undefined
                : timeUnit === "week"
                ? t("Week")
                : t("Month")
            }
            position="bottom"
            axisId="months"
          />
          {showIgnitionQualityCount && (
            <ChartsYAxis
              label={hideYAxisLabels ? undefined : t("Ignitions")}
              position="left"
              axisId="burnCount"
            />
          )}
          {showGoodInPercent && (
            <ChartsYAxis
              label={hideYAxisLabels ? undefined : t("Percentage")}
              position="right"
              axisId="percentage"
            />
          )}
          <BarPlot borderRadius={6} />
          {showGoodInPercent &&
            !showIgnitionQualityCount &&
            !hideAverageGoodInPercent && (
              <ChartsReferenceLine
                y={averageGoodInPercent}
                label={`${t("Average")}: ${MathUtils.round(
                  averageGoodInPercent
                )} %`}
                labelAlign={"start"}
                labelStyle={{
                  fontSize: "smaller",
                  fill: theme.palette.text.secondary,
                }}
                lineStyle={{
                  strokeDasharray: "10 5",
                  stroke: theme.palette.text.secondary,
                }}
                axisId={"percentage"}
              />
            )}
          <LinePlot />
          <MarkPlot
            slots={{
              mark: (props: MarkElementProps) => {
                return <MarkElement {...props} />;
              },
            }}
          />
          <ChartsAxisHighlight x={showIgnitionQualityCount ? "band" : "line"} />
          <ChartsTooltip trigger={"axis"} />
        </ResponsiveChartContainer>
      </MuiChartContainer>
    </Stack>
  );
};

import React, {
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Theme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

import { createTheme, CssBaseline, GlobalStyles } from "@mui/material";
import {
  _throw,
  IllegalArgumentError,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { AppThemeUtils } from "./AppThemeUtils";
import { GlobalStylesProps as StyledGlobalStylesProps } from "@mui/system/GlobalStyles/GlobalStyles";
import { merge } from "lodash";
import { useThemeUserSettingWithDefault } from "./useThemeUserSettingWithDefault";

const defaultGlobalStyles: StyledGlobalStylesProps<Theme>["styles"] = {
  html: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  body: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  "#root": {
    height: "100%",
    width: "100%",
  },
};

const GlobalStyle = createGlobalStyle`

`;

type AppThemeContextType = {
  themeName: string;
  themeOptions: ThemeOptions;
  setTheme: React.Dispatch<SetStateAction<string>>;
  setThemeOptions: (themeName: string, newOptions: ThemeOptions) => void;
};

export const AppThemeContext = React.createContext<AppThemeContextType>(
  undefined as unknown as AppThemeContextType
);

export interface AppThemeProviderProps {
  initialTheme?: string;
  overrideTheme?: string;
  themes: Record<string, ThemeOptions>;
  additionalGlobalStyles?: (
    theme: Theme
  ) => StyledGlobalStylesProps<Theme>["styles"];
  children?: ReactNode | ((theme: Theme) => ReactNode);
}

export const AppThemeProvider: FC<AppThemeProviderProps> = (props) => {
  const initialTheme =
    props.initialTheme ??
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

  const [themeName, setThemeName] =
    useThemeUserSettingWithDefault(initialTheme);

  const [themeOptions, setThemeOptions] = useState<
    Record<string, ThemeOptions>
  >(props.themes);
  const [themes, setThemes] = useState<Record<string, Theme>>(
    AppThemeUtils.createThemes(props.themes)
  );

  const useThemeName =
    props.overrideTheme != null ? props.overrideTheme : themeName;

  const currentTheme =
    themes[useThemeName] ?? _throw(new NullError(`themes[${useThemeName}]`));
  const currentThemeOptions =
    themeOptions[useThemeName] ??
    _throw(new NullError(`themeOptions[${useThemeName}]`));

  const globalStyles: StyledGlobalStylesProps<Theme>["styles"] = useMemo(() => {
    return merge(
      defaultGlobalStyles,
      props.additionalGlobalStyles?.(currentTheme)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme]);

  useEffect(() => {
    setThemeOptions(props.themes);
    setThemes(AppThemeUtils.createThemes(props.themes));
  }, [props.themes]);

  const handleSetThemeOptions = (themeName: string, options: ThemeOptions) => {
    const exising = themes[themeName];
    if (exising == null) {
      throw new IllegalArgumentError("Theme does not exist: " + themeName);
    }
    const newTheme = createTheme(exising, options);
    setThemes((prevState) => {
      return {
        ...prevState,
        [themeName]: newTheme,
      };
    });
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <StyledThemeProvider theme={currentTheme}>
        <CssBaseline enableColorScheme={true} />
        <GlobalStyles styles={globalStyles} />
        <GlobalStyle />
        <AppThemeContext.Provider
          value={{
            themeName: useThemeName,
            themeOptions: currentThemeOptions,
            setTheme: setThemeName,
            setThemeOptions: handleSetThemeOptions,
          }}
        >
          {typeof props.children === "function"
            ? props.children(currentTheme)
            : props.children}
        </AppThemeContext.Provider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export const useSetAppTheme = (): React.Dispatch<SetStateAction<string>> => {
  const context = useContext(AppThemeContext);

  if (context === undefined) {
    throw new Error("useSetAppTheme must be used within a AppThemeProvider");
  }
  return context.setTheme;
};

export const useAppTheme = (): AppThemeContextType => {
  const context = useContext(AppThemeContext);
  if (context === undefined) {
    throw new Error("useAppTheme must be used within a AppThemeProvider");
  }
  return context;
};

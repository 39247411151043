import React, { FC } from "react";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { IconButton } from "@mui/material";

export interface ThemeToggleButtonProps {
  value: string;
  onChange: React.Dispatch<string>;
}

export const ThemeToggleButton: FC<ThemeToggleButtonProps> = (props) => {
  const { value, onChange } = props;
  const handleClick = () => {
    onChange(value === "dark" ? "light" : "dark");
  };
  return (
    <IconButton onClick={handleClick}>
      {value === "light" && <DarkModeRoundedIcon />}
      {value === "dark" && <LightModeRoundedIcon />}
    </IconButton>
  );
};

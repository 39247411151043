import React, { FC, FocusEvent, ReactNode } from "react";
import { PropertyValue } from "./PropertyValue";
import { PropertyValueType } from "./PropertyValueType";
import { TextField_size } from "shared-ts-mui";
import { SxProps } from "@mui/system";
import { ValueOptions } from "./ValueOptions";
import { InputBaseProps } from "@mui/material/InputBase";
import { EditComponentTypeName } from "./EditComponentTypeName";
import { SliderProps } from "@mui/material";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import { resolveEditComponentTypeName } from "./resolveEditComponentTypeName";
import { resolveEditComponent } from "./resolveEditComponent";

export interface PropertyEditProps {
  label: string;
  name?: string;
  value: PropertyValue;
  removable?: boolean;
  disableFuture?: boolean;
  multiline?: boolean;
  valueOptions?: ValueOptions;
  onChange: (value: PropertyValue, name: string) => void;
  onBlur?: (event: FocusEvent) => void;
  unit?: string;
  type?: PropertyValueType;
  editComponent?: EditComponentTypeName;
  editComponentProps?: {
    Slider: SliderProps | undefined;
  };
  size?: TextField_size;
  fullWidth?: boolean;
  info?: ReactNode;
  autoFocus?: boolean;
  autoComplete?: string;
  helperText?: ReactNode;
  error?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
}

export const PropertyEdit: FC<PropertyEditProps> = (props) => {
  const type = props.type ?? resolvePropertyValueTypeFromValue(props.value);
  const editComponentTypeName = resolveEditComponentTypeName(
    type,
    props.valueOptions,
    props.editComponent
  );
  return resolveEditComponent(props, editComponentTypeName);
};
